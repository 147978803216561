import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { setEditingLeadClose } from "../../data/redux/leads/leads";
import { createLead, editLead } from "../../data/redux/leads/thunk";
import { getErrorMessage } from "../../../utils/helper";
import {
  getInitialValueByFieldType,
  getValidationSchemaByFieldType,
} from "../contacts/utils/helper";

interface AddLeadModalProps {
  campaignId?: string;
  pageRoot: string;
  modelCategory: string;
  onSubmitFinish?: () => void;
}

const AddLeadModal = ({
  campaignId,
  pageRoot,
  modelCategory,
  onSubmitFinish,
}: AddLeadModalProps) => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.lead?.leads?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.lead?.leads?.edit?.data
  );

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const [requiredFields, setRequiredFields] = useState([]);
  const [showFields, setShowFields] = useState([]);

  useEffect(() => {
    const pageFieldAdminData =
      fieldsAdminData?.[pageRoot]?.["Lead"]?.[modelCategory]?.["data"];
    if (pageFieldAdminData?.length) {
      const isShownFields = pageFieldAdminData.filter(
        (field: any) =>
          field.is_show &&
          field.field_type !== "contact" &&
          field.original_name !== "lead_id" &&
          field.original_name !== "original_lead_id"
      );
      setShowFields(isShownFields);

      const result = isShownFields.filter((field: any) => field.is_required);
      setRequiredFields(result);
    }
  }, [fieldsAdminData]);

  const initialValues = useMemo(() => {
    if (showFields?.length) {
      const result = showFields.reduce(
        (initialData: any, currentField: any) => {
          const { original_name, display_field_type, field_type } =
            currentField;

          initialData[original_name] = editingData
            ? field_type === "custom"
              ? editingData?.["leads_custom_values"]?.[0]?.[
                  "lead_custom_field_value"
                ]?.[original_name]
              : editingData[original_name]
            : getInitialValueByFieldType(display_field_type);
          return initialData;
        },
        {}
      );
      return result;
    }
    return {};
  }, [JSON.stringify(showFields), JSON.stringify(editingData)]);

  const validationSchema = useMemo(() => {
    if (requiredFields?.length) {
      const result = requiredFields.reduce(
        (initialSchema: any, currentField: any) => {
          const { original_name, display_name, display_field_type } =
            currentField;
          initialSchema[original_name] = getValidationSchemaByFieldType(
            display_name,
            display_field_type
          );
          return initialSchema;
        },
        {}
      );

      return result;
    }
    return {};
  }, [JSON.stringify(requiredFields)]);

  const customFields = useMemo(() => {
    if (showFields?.length) {
      const result = showFields.reduce((fieldsData: any, currentField: any) => {
        const { field_type, original_name } = currentField;
        if (field_type === "custom") {
          fieldsData.push(original_name);
        }
        return fieldsData;
      }, []);
      return result;
    }
    return [];
  }, [JSON.stringify(showFields)]);

  return (
    <>
      {/* Add/Edit Lead */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Lead" : "Add New Lead"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setEditingLeadClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            {!showFields?.length ? (
              <>No show fields</>
            ) : (
              <div className="pro-create">
                <Formik
                  initialValues={{ ...initialValues, submit: null }}
                  validationSchema={Yup.object().shape(validationSchema)}
                  enableReinitialize={true}
                  onSubmit={async (values, { resetForm, setErrors }) => {
                    try {
                      let newLead: any = values;

                      // get custom fields value

                      const customFieldsValue = customFields.reduce(
                        (fieldsValue: any, currentField: string) => {
                          const result = {
                            ...(fieldsValue || {}),
                            [currentField]: newLead?.[currentField],
                          };
                          return result;
                        },
                        {}
                      );

                      let res: any;
                      const payload = {
                        ...newLead,
                        campaign: campaignId,
                        custom_fields: customFieldsValue,
                      };
                      if (editingData) {
                        res = await dispatch(
                          editLead({
                            lead_id: editingData.lead_id,
                            data: payload,
                          })
                        );
                      } else {
                        res = await dispatch(createLead(payload));
                      }

                      if (res && res?.error) {
                        let errorData;
                        try {
                          errorData = JSON.parse(res?.error?.message);
                          setErrors(errorData);
                        } catch (_) {
                          errorData = "";
                          setErrors({ submit: getErrorMessage(errorData) });
                        }
                      } else {
                        resetForm();
                        onSubmitFinish && onSubmitFinish();
                        dispatch(setEditingLeadClose());
                      }
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    isSubmitting,
                    touched,
                    values,
                  }) => (
                    <form noValidate onSubmit={handleSubmit}>
                      {/* Lead Info */}

                      <div className="row gy-2">
                        {showFields.map(
                          ({
                            display_name,
                            display_field_type,
                            original_name,
                          }) => (
                            <div className="col-md-12" key={original_name}>
                              <div className="form-wrap">
                                <label className="col-form-label">
                                  {display_name}
                                </label>
                                {display_field_type === "select" ? (
                                  <Select
                                    defaultValue={[]}
                                    name={original_name}
                                    options={[]}
                                    onBlur={handleBlur}
                                    value={null}
                                    onChange={(option: any) => {
                                      setFieldValue(
                                        original_name,
                                        option.value
                                      );
                                    }}
                                  />
                                ) : display_field_type === "boolean" ? (
                                  <div className="form-switch form-check-md">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked={values?.[original_name]}
                                      name={original_name}
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                    />
                                  </div>
                                ) : display_field_type === "text" ? (
                                  <textarea
                                    name={original_name}
                                    className="form-control"
                                    value={values?.[original_name]}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                ) : display_field_type === "datetime" ? (
                                  <div className="icon-form">
                                    <span className="form-icon">
                                      <i className="ti ti-calendar-check" />
                                    </span>
                                    <DatePicker
                                      className="form-control datetimepicker deals-details w-100"
                                      name={original_name}
                                      value={values?.[original_name]}
                                      selected={values?.[original_name]}
                                      onChange={(val) =>
                                        setFieldValue(original_name, val)
                                      }
                                      dateFormat="yyyy-MM-dd HH:mm:ss"
                                    />
                                  </div>
                                ) : (
                                  <input
                                    type={
                                      display_field_type === "char"
                                        ? "text"
                                        : (
                                            display_field_type as string
                                          )?.includes("email")
                                        ? "email"
                                        : (
                                            display_field_type as string
                                          )?.includes("phone")
                                        ? "tel"
                                        : "number"
                                    }
                                    name={original_name}
                                    className="form-control"
                                    value={values?.[original_name]}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                )}
                              </div>
                              {touched?.[original_name] &&
                                errors?.[original_name] && (
                                  <Alert
                                    message={errors?.[original_name] as string}
                                    type="error"
                                  />
                                )}
                            </div>
                          )
                        )}
                      </div>
                      <div className="row gy-2">
                        <div className="col-md-12">
                          <div className="form-wrap">
                            {errors.submit && (
                              <Alert
                                message={errors.submit as string}
                                type="error"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="submit-button text-end">
                        <Link
                          to="#"
                          className="btn btn-light sidebar-close"
                          onClick={() => dispatch(setEditingLeadClose())}
                        >
                          Cancel
                        </Link>
                        <button type="submit" className="btn btn-primary">
                          {editingData ? "Edit" : "Create"}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* /Add/Edit Lead */}
    </>
  );
};

export default AddLeadModal;

import { createAsyncThunk } from "@reduxjs/toolkit";
import fieldsAdminService from "../../../../services/fieldsAdmin";

// Field Admin Actions
export const getAdminFields = createAsyncThunk(
  "admin/get-admin-fields",
  async (payload: any) => {
    const { page_root, model_name, model_category } = payload;
    const response = await fieldsAdminService.getAdminFields(
      page_root,
      model_name,
      model_category
    );
    if (!response) {
      throw new Error("Retrieve Admin Fields Failed");
    }

    return response;
  }
);

export const getAdminField = createAsyncThunk(
  "admin/get-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.getAdminField(payload);
    if (!response) {
      throw new Error("Retrieve Admin Field Failed");
    }

    return response;
  }
);

export const createAdminField = createAsyncThunk(
  "admin/create-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.createAdminField(payload);
    if (!response) {
      throw new Error("Create Admin Field Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const editAdminField = createAsyncThunk(
  "admin/edit-admin-field",
  async (payload: any) => {
    const response = await fieldsAdminService.editAdminField(payload);
    if (!response) {
      throw new Error("Edit Admin Field Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteAdminField = createAsyncThunk(
  "admin/delete-admin-field",
  async (payload: any) => {
    try {
      const { id } = payload;
      if (id) {
        const response = await fieldsAdminService.removeAdminField(id);
        if (!response) {
          throw new Error("Delete Admin Field Failed!");
        }
        return response;
      }
      return null;
    } catch (e) {
      throw new Error("Delete Admin Field Failed!");
    }
  }
);

export const retrieveAdminFields = createAsyncThunk(
  "admin/retrieve-admin-fields",
  async (payload: any) => {
    const { page_root, model_name, model_category } = payload;
    const response = await fieldsAdminService.retrieveAdminFields(
      page_root,
      model_name,
      model_category
    );
    if (!response) {
      throw new Error("Retrieve Admin Fields Failed!");
    }

    return response;
  }
);

export const retrieveModelFields = createAsyncThunk(
  "admin/retrieve-model-fields",
  async (payload: any) => {
    const { model_name } = payload;
    const response = await fieldsAdminService.retrieveModelFields(model_name);
    if (!response) {
      throw new Error("Retrieve Model Fields Failed!");
    }

    return response;
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSession } from "../../../../utils/helper";
import AuthService from "../../../../services/auth";
import secureLocalStorage from "react-secure-storage";
const localStorage = secureLocalStorage;

export const login = createAsyncThunk(
  "auth/login",
  async (payload: { email: string; password: string }) => {
    const { email, password } = payload;
    const response = await AuthService.login(email, password);
    if (response) {
      const { access, refresh } = response;
      setSession(access, refresh);
    } else {
      setSession(null, null);
      throw new Error("Login is failed by this credential!");
    }

    const checkIpRestrictionResponse = await AuthService.checkIpRestriction();
    if (checkIpRestrictionResponse.status === 403) {
      await AuthService.logout();
      setSession(null, null);
      throw new Error(
        "Your Ip Address is not allowed, Please contact your admin!"
      );
    }

    return response;
  }
);

export const logout = createAsyncThunk("auth/logout", async () => {
  setSession(null, null);
});

export const initAuth = createAsyncThunk(
  "auth/init",
  async (arg, { getState }) => {
    try {
      const state: any = getState();
      const accessToken =
        localStorage.getItem("accessToken") || state?.currentUser?.token;
      const isValidToken = await AuthService.verifyToken(accessToken);
      if (accessToken && isValidToken) {
        const response = await AuthService.getUser();
        const user = response;
        return user;
      } else {
        return null;
      }
    } catch (err) {
      return null;
    }
  }
);

export const getUsers = createAsyncThunk("auth/users", async () => {
  const response = await AuthService.getUsers();
  if (!response) {
    throw new Error("Retrieve Users is failed.");
  }
  return response;
});

export const createUser = createAsyncThunk(
  "auth/create-user",
  async (payload: any) => {
    const response = await AuthService.createUser(payload);
    if (!response) {
      throw new Error("Create User Failed!");
    }

    return response;
  }
);

export const getUser = createAsyncThunk(
  "auth/retrieve-user",
  async (payload: any) => {
    const response = await AuthService.retrieveUser(payload);
    if (!response) {
      throw new Error("Get User Failed!");
    }

    return response;
  }
);

export const editUser = createAsyncThunk(
  "auth/edit-user",
  async (payload: any) => {
    const response = await AuthService.editUser(payload);
    if (!response) {
      throw new Error("Edit User Failed!");
    }

    return response;
  }
);

export const deleteUser = createAsyncThunk(
  "auth/delete-user",
  async (payload: any) => {
    const response = await AuthService.removeUser(payload);
    if (!response) {
      throw new Error("Remove User Failed!");
    }

    return response;
  }
);

export const getPermissions = createAsyncThunk("auth/permissions", async () => {
  const response = await AuthService.getPermissions();
  if (!response) {
    throw new Error("Retrieve Permissions is failed.");
  }
  return response;
});

export const getUserGroups = createAsyncThunk("auth/user-groups", async () => {
  const response = await AuthService.getUserGroups();
  if (!response) {
    throw new Error("Retrieve User Groups is failed.");
  }
  return response;
});

export const createUserGroup = createAsyncThunk(
  "auth/create-user-group",
  async (payload: any) => {
    const response = await AuthService.createUserGroup(payload);
    if (!response) {
      throw new Error("Create User Group Failed!");
    }

    return response;
  }
);

export const getUserGroup = createAsyncThunk(
  "auth/retrieve-user-group",
  async (payload: any) => {
    const response = await AuthService.retrieveUserGroup(payload);
    if (!response) {
      throw new Error("Get User Group Failed!");
    }

    return response;
  }
);

export const editUserGroup = createAsyncThunk(
  "auth/edit-user-group",
  async (payload: any) => {
    const response = await AuthService.editUserGroup(payload);
    if (!response) {
      throw new Error("Edit User Group Failed!");
    }

    return response;
  }
);

export const deleteUserGroup = createAsyncThunk(
  "auth/delete-user-group",
  async (payload: any) => {
    const response = await AuthService.removeUserGroup(payload);
    if (!response) {
      throw new Error("Remove User Group Failed!");
    }

    return response;
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  async (payload: { email: string }) => {
    const { email } = payload;
    const response = await AuthService.resetPassword(email);
    if (!response) {
      throw new Error("Reset Password is failed.");
    }
    return response;
  }
);

export const resetPasswordConfirm = createAsyncThunk(
  "auth/reset-password-confirm",
  async (payload: {
    confirmPassword: string;
    newPassword: string;
    uuid?: string;
    token?: string;
  }) => {
    const { confirmPassword, newPassword, uuid, token } = payload;
    const response = await AuthService.resetConfirmPassword(
      newPassword,
      confirmPassword,
      uuid,
      token
    );
    if (!response) {
      throw new Error("Reset Password is failed.");
    }
    return response;
  }
);

export const all_routes = {
  // dashboard routes
  dealsDashboard: "/dashboard/deals-dashboard",
  leadsDashboard: "/dashboard/leads-dashboard",
  projectDashboard: "/dashboard/project-dashboard",

  bankAccounts: "/bank-accounts",
  blankPage: "/",
  calendar: "/calendar",
  comingSoon: "/coming-soon",
  welcomePage: "/welcome",

  // auth routes routes
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  twoStepVerification: "/two-step-verification",
  success: "/success",
  emailVerification: "/email-verification",
  lockScreen: "/lock-screen",
  resetPassword: "/reset-password/:uuid/:token",

  // crm
  groups: "/groups",
  groupDetail: "/group-detail/:id",
  menuDetail: "/menu-detail/:id",
  appConnectivity: "/apps/connectivity/:menuId",

  users: "/users",
  userGroups: "/user-groups",

  contacts: "/contacts",
  leads: "/leads",
  leadDetail: "/leads/:id",
  adminContact: "/contact-admin",
  adminLead: "/lead-admin",
  adminClosedLead: "/closed-lead-admin",

  campaigns: "/apps/campaigns/",
  campaignAdmin: "/apps/campaigns/admin/:campaignId",
  campaignLeads: "/campaigns/leads/:campaignId",

  //base-ui
  uiAlerts: "/ui-alerts",
  uiAccordion: "/ui-accordion",
  uiAvatar: "/ui-avatar",
  uiBadges: "/ui-badges",
  uiBorders: "/ui-borders",
  uiButtons: "/ui-buttons",
  uiButtonsGroup: "/ui-buttons-group",
  uiBreadcrumb: "/ui-breadcrumb",
  uiCards: "/ui-cards",
  uiCarousel: "/ui-carousel",
  uiColor: "/ui-color",
  uiDropdowns: "ui-dropdowns",

  // pages routes
  error404: "/error-404",
  error500: "/error-500",
  underMaintenance: "/under-maintenance",
  errorNoPermission: "/no-permission",

  connectedApps: "/general-settings/connected-apps",
  notification: "/general-settings/notification",
  profile: "/general-settings/profile",
  security: "/general-settings/security",
};

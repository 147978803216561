import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Form, Formik, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
// @ts-ignore
import { TableauEventType } from "https://app.bizrampbi.com/javascripts/api/tableau.embedding.3.latest.min.js";
import { createMenu, editMenu } from "../../../data/redux/menu/thunk";
import pkg from "lodash";
import { Alert } from "antd";
import { setEditingMenuClose } from "../../../data/redux/menu/menu";
import MenuService from "../../../../services/menu";
import IconSelector from "../../../common/iconSelector";
import { getErrorMessage } from "../../../../utils/helper";
import { getCampaigns } from "../../../data/redux/campaign/thunk";
import { all_routes } from "../../../../feature-module/router/all_routes";

const { isEmpty, uniqWith } = pkg;

const urlValidationSchema = Yup.string().test(
  "is-url",
  "Invalid URL",
  (value) => {
    if (!value) return true; // Allow empty values if not required
    try {
      const url = new URL(value);
      return (
        url.protocol === "http:" ||
        url.protocol === "https:" ||
        url.hostname === "localhost"
      );
    } catch {
      return false;
    }
  }
);

interface AddMenuModalProps {
  parent: any;
  level: number;
}

const AddMenuModal = ({ parent, level }: AddMenuModalProps) => {
  const dispatch = useDispatch<any>();

  const routes = all_routes;

  const modalToggle = useSelector(
    (state: any) => state?.menu?.menus?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.menu?.menus?.edit?.data
  );
  const campaignData =
    useSelector((state: any) => state.campaign.campaigns?.list) || [];
  const groupData = useSelector((state: any) => state.menu.groups?.list) || [];
  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];
  const [jwtToken, setToken] = useState();

  const parentOptions = useMemo(() => {
    if (level === 1) {
      if (groupData?.length) {
        const options = groupData.map((group: any) => ({
          label: group.name,
          value: group.id,
        }));
        return options;
      }
    } else if (level > 1) {
      if (menuData?.length) {
        const options = menuData
          .filter((menu: any) => (menu.level || 1) === level - 1)
          .map((group: any) => ({
            label: group.name,
            value: group.id,
          }));
        return options;
      }
    }

    return [];
  }, [level, JSON.stringify(groupData)]);

  const MENU_CATEGORIES = [
    {
      label: "Tableau Page",
      value: "Tableau Page",
    },
    {
      label: "New Tab Message Page",
      value: "New Tab Message Page",
    },
    {
      label: "External Page",
      value: "External Page",
    },
    {
      label: "Contact Page",
      value: "Contact Page",
    },
    {
      label: "Contact Admin Page",
      value: "Contact Admin Page",
    },

    {
      label: "Lead Admin Page",
      value: "Lead Admin Page",
    },

    {
      label: "Lead Page",
      value: "Lead Page",
    },

    {
      label: "Closed Lead Admin Page",
      value: "Closed Lead Admin Page",
    },

    {
      label: "Campaign Page",
      value: "Campaign Page",
    },
  ];

  const vizRef = useRef(null);
  const [viz, setViz] = useState();
  const [tableauFilters, setTableauFilters] = useState<any[]>([]);

  const [tableauFilterValues, setFilterValues] = useState<{
    [key: string]: any;
  }>({});
  const [isFiltersLoading, setFiltersLoading] = useState(false);

  const [filterDropdownValues, setFilterDropdownValues] = useState<any[]>([]);
  const tableauFilterOptions = useMemo(() => {
    if (tableauFilters?.length) {
      const options = tableauFilters?.map((filter: any) => ({
        label: filter.name,
        value: filter.name,
      }));
      return options;
    }
    return [];
  }, [JSON.stringify(tableauFilters)]);

  const enabledTableauFilters = useMemo(() => {
    let result: any[] = [];
    if (tableauFilters?.length && filterDropdownValues?.length) {
      result = tableauFilters.filter((filter: any) =>
        filterDropdownValues.find((option: any) => option.value === filter.name)
      );
    }
    return result;
  }, [JSON.stringify(filterDropdownValues), JSON.stringify(tableauFilters)]);

  const AVAILABLE_CAMPAIGNS = useMemo(() => {
    const result = campaignData.map((campaign: any) => ({
      id: campaign.campaign_id,
      label: campaign.title,
      value: `${process.env.REACT_APP_CLIENT_URL}${routes.campaignLeads.replace(
        ":campaignId",
        campaign.campaign_id
      )}`,
    }));
    return result;
  }, [JSON.stringify(campaignData)]);

  const getToken = async () => {
    const response = await MenuService.getJWT();

    if (!response) {
      throw new Error("Get JWT Failed!");
    }

    setToken(response);
  };

  const [iconSelectorShow, setSelectorShow] = useState(false);
  const [selectedMenuIcon, setMenuIcon] = useState(
    editingData ? editingData.menu_icon : null
  );

  useEffect(() => {
    if (!isEmpty(editingData)) {
      setMenuIcon(editingData.menu_icon);
      const dropdownValues: any[] = [];
      const filterValues = (
        editingData?.["tableau_filter_values"] || []
      )?.reduce((result: any, currentFilter: any) => {
        let nextResult = { ...result };
        if (currentFilter.value) {
          dropdownValues.push({
            label: currentFilter.name,
            value: currentFilter.name,
          });
          nextResult = {
            ...nextResult,
            [currentFilter.name]: currentFilter.value,
          };
        }
        return nextResult;
      }, {});
      setFilterValues(filterValues);
      setFilterDropdownValues(dropdownValues);
    }
  }, [JSON.stringify(editingData)]);

  const formik = useFormik({
    initialValues: {
      name: editingData?.name || "",
      parent: editingData
        ? editingData.group || editingData.parent
        : parent?.id,
      url: editingData ? editingData.url : "",
      menu_page_category: editingData ? editingData.menu_page_category : false,
      assigned_user_groups:
        editingData?.assigned_user_groups?.map((user: any) => user.id) || [],
      submit: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(255).required("Name is required"),
      assigned_user_groups: Yup.array(),
      url: urlValidationSchema.nullable(),
      menu_page_category: Yup.string()
        .max(255)
        .required("Page type is required"),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        const { parent, ...rest }: any = values;
        let newMenu = rest;

        if (tableauFilters.length) {
          if (!isEmpty(tableauFilterValues)) {
            if (enabledTableauFilters.length) {
              newMenu["tableau_filter_values"] = enabledTableauFilters.map(
                (filter) => ({
                  ...filter,
                  value: tableauFilterValues?.[filter.name],
                })
              );
            } else {
              newMenu["tableau_filter_values"] = [];
            }
          } else {
            newMenu["tableau_filter_values"] = [];
          }
        }

        newMenu.assigned_user_groups_ids = values?.assigned_user_groups || [];
        newMenu.assigned_user_groups = parent?.assigned_user_groups?.filter(
          (user: any) =>
            ((values?.assigned_user_groups || []) as any[]).includes(user.id)
        );
        if (level === 1) {
          newMenu.group = parent;
        } else if (level > 1) {
          newMenu.parent = parent;
        }

        newMenu.level = level;
        newMenu.menu_icon = selectedMenuIcon;

        let res: any;
        if (editingData) {
          res = await dispatch(editMenu({ id: editingData.id, data: newMenu }));
        } else {
          res = await dispatch(createMenu(newMenu));
        }

        if (res && res?.error) {
          let errorData;
          try {
            errorData = JSON.parse(res?.error?.message);
            setErrors(errorData);
          } catch (_) {
            errorData = "";
            setErrors({ submit: getErrorMessage(errorData) });
          }
        } else {
          resetForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const userOptions = useMemo(() => {
    if (parent?.assigned_user_groups) {
      const options = parent?.assigned_user_groups.map((group: any) => ({
        label: group.name,
        value: group.id,
      }));
      if (!editingData) {
        setFieldValue("parent", parent.id);
      }

      return options;
    }
    return [];
  }, [JSON.stringify(parent)]);

  const loadViz = () => {
    setFiltersLoading(true);
    const url = formik.values?.["url"];
    setViz(
      // @ts-ignore
      <tableau-viz
        ref={vizRef}
        id="tableauViz"
        src={url}
        hide-tabs={false}
        token={jwtToken}
        toolbar="hidden"
        disableUrlActionsPopups={true}
      />
    );
  };

  const retrieveFilters = () => {
    if (jwtToken && !tableauFilters?.length) {
      loadViz();
    }
  };

  useEffect(() => {
    const pageType = formik.values?.["menu_page_category"];
    if (pageType === "Tableau Page" && !jwtToken) {
      getToken();
    }
  }, [JSON.stringify(formik.values)]);

  useEffect(() => {
    if (viz) {
      const vizTimeout = setTimeout(() => {
        const tableauViz = document.getElementById("tableauViz");
        if (tableauViz) {
          tableauViz.addEventListener(
            TableauEventType.FirstInteractive,
            async (event) => {
              let activeSheet = event.target?.workbook?.activeSheet;
              const sheets = activeSheet?.worksheets || [];
              const sheetFilters = [];
              for (const sheet of sheets) {
                const filters = await sheet?.getFiltersAsync();
                const filtersList = (filters || [])?.map((filter: any) => ({
                  name: filter.fieldName,
                  type: filter.filterType,
                }));
                sheetFilters.push(...filtersList);
              }
              const commonFilters = uniqWith(
                sheetFilters,
                (filterA, filterB) =>
                  filterA.name === filterB.name && filterA.type === filterB.type
              );
              setTableauFilters(commonFilters);
              setFiltersLoading(false);
            }
          );
        }
      }, 2000);

      return () => clearTimeout(vizTimeout);
    }
  }, [viz]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getCampaigns());
    }
  }, [dispatch]);

  const onCloseModal = () => {
    dispatch(setEditingMenuClose());
    setSelectorShow(false);
  };

  return (
    <FormikProvider value={formik}>
      {/* Add/Edit Menu */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Menu" : "Add New Menu"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => onCloseModal()}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* Menu Info */}

                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Menu Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.name && errors.name && (
                      <Alert message={errors.name as string} type="error" />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Menu Icon <span className="text-danger">*</span>
                      </label>
                      <IconSelector
                        show={iconSelectorShow}
                        setShow={(payload: boolean) => setSelectorShow(payload)}
                        data={selectedMenuIcon}
                        selectData={setMenuIcon}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Parent <span className="text-danger">*</span>
                      </label>
                      <Select
                        name="parent"
                        options={parentOptions}
                        onBlur={handleBlur}
                        value={
                          values.parent
                            ? parentOptions.find(
                                (option: any) => option.value === values.parent
                              )
                            : null
                        }
                        onChange={(option: any) => {
                          setFieldValue("parent", option.value);
                        }}
                      />
                    </div>
                    {touched.parent && errors.parent && (
                      <Alert message={errors.parent as string} type="error" />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Assigned Groups <span className="text-danger">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="assigned_user_groups"
                        options={userOptions}
                        onBlur={handleBlur}
                        value={
                          userOptions
                            ? userOptions.filter((option: any) =>
                                (
                                  (values.assigned_user_groups || []) as any[]
                                ).includes(option.value)
                              )
                            : []
                        }
                        onChange={(options: any) => {
                          setFieldValue(
                            "assigned_user_groups",
                            (options || []).map((option: any) => option.value)
                          );
                        }}
                      />
                    </div>
                    {touched.assigned_user_groups &&
                      errors.assigned_user_groups && (
                        <Alert
                          message={errors.assigned_user_groups as string}
                          type="error"
                        />
                      )}
                  </div>
                  {values.menu_page_category === "Campaign Page" ? (
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label className="col-form-label">Campaigns</label>
                        <Select
                          defaultValue={[]}
                          name="url"
                          options={AVAILABLE_CAMPAIGNS}
                          onBlur={handleBlur}
                          value={
                            AVAILABLE_CAMPAIGNS
                              ? AVAILABLE_CAMPAIGNS.find(
                                  (option: any) => values.url === option.value
                                )
                              : null
                          }
                          onChange={(option: any) => {
                            setFieldValue("url", option.value);
                          }}
                        />
                      </div>
                      {touched.url && errors.url && (
                        <Alert message={errors.url as string} type="error" />
                      )}
                    </div>
                  ) : (
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label className="col-form-label">Url</label>
                        <input
                          type="text"
                          name="url"
                          className="form-control"
                          value={values.url}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                      {touched.url && errors.url && (
                        <Alert message={errors.url as string} type="error" />
                      )}
                    </div>
                  )}

                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Menu Category <span className="text-danger">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        name="menu_page_category"
                        options={MENU_CATEGORIES}
                        onBlur={handleBlur}
                        value={
                          MENU_CATEGORIES
                            ? MENU_CATEGORIES.find((option: any) =>
                                (
                                  (values.menu_page_category || []) as any[]
                                ).includes(option.value)
                              )
                            : null
                        }
                        onChange={(option: any) => {
                          setFieldValue("menu_page_category", option.value);
                        }}
                      />
                    </div>
                    {touched.menu_page_category &&
                      errors.menu_page_category && (
                        <Alert
                          message={errors.menu_page_category as string}
                          type="error"
                        />
                      )}
                  </div>

                  {values.menu_page_category === "Tableau Page" ? (
                    <div className="col-md-12">
                      <div className="row gap-2">
                        <div className="col-md-12">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={() => retrieveFilters()}
                            disabled={
                              !jwtToken ||
                              tableauFilters?.length > 0 ||
                              isFiltersLoading
                            }
                          >
                            Retrieve Filters
                          </button>
                        </div>
                        <div className="col-md-12">
                          <div>Configuration ( Filters )</div>
                        </div>
                        <div className="col-md-12">
                          {isFiltersLoading ? (
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-flex justify-content-center mb-4">
                                  <div className="spinner-border" role="status">
                                    <span className="visually-hidden">
                                      Loading...
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="row gy-2">
                              {tableauFilterOptions?.length ? (
                                <div className="col-md-12">
                                  <Select
                                    defaultValue={[]}
                                    isMulti
                                    placeholder="Select Filter"
                                    className="w-full"
                                    options={tableauFilterOptions}
                                    value={filterDropdownValues}
                                    onChange={(options: any) => {
                                      setFilterDropdownValues(options);
                                    }}
                                  />
                                </div>
                              ) : null}
                              {enabledTableauFilters.map((filter) => (
                                <div className="col-md-12" key={filter.name}>
                                  {filter?.type === "range" ? (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-wrap">
                                          <label className="col-form-label">
                                            {filter.name}
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-wrap w-100">
                                          <label className="col-form-label">
                                            Min
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Min Value"
                                            value={
                                              tableauFilterValues?.[filter.name]
                                            }
                                            onChange={(e) => {
                                              setFilterValues((prev: any) => ({
                                                ...prev,
                                                [filter.name]: {
                                                  ...(prev?.[filter.name] ||
                                                    {}),
                                                  min: e.target.value,
                                                },
                                              }));
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-wrap w-100">
                                          <label className="col-form-label">
                                            Max
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Min Value"
                                            value={
                                              tableauFilterValues?.[filter.name]
                                            }
                                            onChange={(e) => {
                                              setFilterValues((prev: any) => ({
                                                ...prev,
                                                [filter.name]: {
                                                  ...(prev?.[filter.name] ||
                                                    {}),
                                                  max: e.target.value,
                                                },
                                              }));
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  ) : filter?.type === "categorical" ? (
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="d-flex">
                                          <div className="form-wrap w-100">
                                            <label className="col-form-label">
                                              {filter.name}
                                            </label>
                                            <input
                                              type="text"
                                              className="form-control"
                                              placeholder="Enter Configuration Value"
                                              value={
                                                tableauFilterValues?.[
                                                  filter.name
                                                ]
                                              }
                                              onChange={(e) => {
                                                const targetValue =
                                                  e.target.value;
                                                const inputValue = targetValue
                                                  .split(",")
                                                  .map((option: string) =>
                                                    option.trim()
                                                  )
                                                  .join(",");
                                                setFilterValues(
                                                  (prev: any) => ({
                                                    ...prev,
                                                    [filter.name]: inputValue,
                                                  })
                                                );
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              ))}
                            </div>
                          )}
                          <div className="col-md-12">
                            <div
                              style={{ visibility: "hidden", height: "10px" }}
                            >
                              {getFieldProps("url")?.value ? viz : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData ? "Edit" : "Create"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </FormikProvider>
  );
};

export default AddMenuModal;

import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { TableData } from "../../../core/data/interface";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import AddGroupModal from "../../../core/modals/menus/group/add_group_modal";
import AddGroupSuccessModal from "../../../core/modals/menus/group/add_group_success_modal";
import { Flex, Tag } from "antd";
import {
  setEditingGroup,
  setRemoveGroup,
} from "../../../core/data/redux/menu/menu";
import RemoveGroupModal from "../../../core/modals/menus/group/remove_group_modal";
import {
  editMenuGroup,
  getMenuGroups,
} from "../../../core/data/redux/menu/thunk";
import pkg from "lodash";
const { isEmpty } = pkg;

const MenuGroupList = () => {
  const route = all_routes;
  const navigate = useNavigate();

  const groupData = useSelector((state: any) => state.menu.groups?.list) || [];
  const dataLoading = useSelector((state: any) => state.menu.groups?.loading);

  const dispatch = useDispatch<any>();

  const loggedInUser = useSelector(
    (state: any) => state.auth?.currentUser?.data
  );

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      !(loggedInUser?.is_admin || loggedInUser?.is_superuser)
    ) {
      navigate("/no-permission");
    }
  }, [navigate, loggedInUser]);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <Link
            to={route.groupDetail.replace(":id", record.id)}
            className="profile-split d-flex flex-column cursor-pointer"
            role="button"
          >
            <span>{record.name}</span>
          </Link>
        </div>
      ),
    },

    {
      title: "Order",
      dataIndex: "order",
      sorter: (a: TableData, b: TableData) => a.order - b.order,
    },

    {
      title: "Assigned Groups",
      dataIndex: "assigned_user_groups",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.assigned_user_groups || []).map((group: any) => (
            <Tag color="processing" key={group.name}>
              {group.name}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text: string, record: any, index: number) =>
        record.created_by.email,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditingGroup(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveGroup(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  const onDragEnd = async (recordId: string, newOrder: number) => {
    const record = groupData?.find((item: any) => item?.id === recordId);
    await dispatch(
      editMenuGroup({
        id: recordId,
        data: {
          ...(record || {}),
          assigned_user_groups_ids: record.assigned_user_groups.map(
            (item: any) => item.id
          ),
          order: newOrder + 1,
        },
        is_new_order: true,
      })
    );
    dispatch(getMenuGroups());
  };

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="page-title">
                      Menu groups
                      {groupData?.length ? (
                        <span className="count-title">{groupData?.length}</span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-4 text-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getMenuGroups());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="card main-card">
                <div className="card-body">
                  {/* Search */}
                  <div className="search-section">
                    <div className="row">
                      <div className="col-md-5 col-sm-4">
                        <div className="form-wrap icon-form">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Groups"
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-8">
                        <div className="export-list text-sm-end">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() => dispatch(setEditingGroup(null))}
                              >
                                <i className="ti ti-square-rounded-plus" /> Add
                                Groups
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Search */}
                  {/* Group List */}
                  <div className="table-responsive custom-table">
                    <Table
                      dataSource={groupData}
                      columns={columns}
                      onDragEnd={onDragEnd}
                      loading={dataLoading}
                    />
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="datatable-length" />
                    </div>
                    <div className="col-md-6">
                      <div className="datatable-paginate" />
                    </div>
                  </div>
                  {/* /Group List */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <AddGroupModal />
      <AddGroupSuccessModal />
      <RemoveGroupModal />
    </div>
  );
};

export default MenuGroupList;

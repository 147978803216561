import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  createLeadComment,
  deleteLeadComment,
  editLeadComment,
  getLead,
} from "../../../core/data/redux/leads/thunk";
import "./index.scss";
import { setEditingLead, setLeadViewClose } from "../../data/redux/leads/leads";
import ImageWithBasePath from "../../common/imageWithBasePath";
import Editor from "react-simple-wysiwyg";
import { setEditingContact } from "../../data/redux/contacts/contacts";
import { Flex } from "antd";

const LeadDetailModal = () => {
  const dispatch = useDispatch<any>();
  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const leadInternalDataFields =
    fieldsAdminData?.["lead"]?.["Lead"]?.["main"]?.["data"];

  const { data: visitingLeadData } =
    useSelector((state: any) => state.lead.leads?.view) || [];

  const [isEditingComment, setIsEditingComment] = useState<{
    [commentId: string]: boolean;
  }>({});

  const [editingCommentsContent, setEditingCommentsContent] = useState<{
    [commentId: string]: string;
  }>({});

  const onChange = (commentId: string, e: any) => {
    setEditingCommentsContent((prev) => ({
      ...prev,
      [commentId]: e.target.value,
    }));
  };

  const handleOnDeleteComment = async (commentId: string) => {
    try {
      await dispatch(deleteLeadComment(commentId));
      await dispatch(getLead(visitingLeadData.lead_id));
    } catch (error) {}
  };

  const handleOnSaveComment = async (commentId: string) => {
    try {
      if (commentId === "add") {
        await dispatch(
          createLeadComment({
            lead: visitingLeadData.lead_id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      } else {
        await dispatch(
          editLeadComment({
            commentId: commentId,
            lead: visitingLeadData.lead_id,
            comment: editingCommentsContent?.[commentId] ?? "",
          })
        );
      }
      await dispatch(getLead(visitingLeadData.lead_id));
      setIsEditingComment((prev) => ({
        ...prev,
        [commentId]: false,
      }));
    } catch (error) {}
  };

  useEffect(() => {
    const leadCommentsData = (visitingLeadData?.lead_comments || []).reduce(
      (prev: any, curr: any) => {
        Object.assign(prev, { [curr.comment_id]: curr.comment });
        return prev;
      },
      {}
    );
    setEditingCommentsContent(leadCommentsData);
  }, [JSON.stringify(visitingLeadData)]);

  useEffect(() => {
    if (visitingLeadData?.lead_id) dispatch(getLead(visitingLeadData.lead_id));
  }, [visitingLeadData?.lead_id]);

  // if (isLeadDataLoading) {
  //   return (
  //     <div className="page-wrapper">
  //       <div className="content">
  //         <div className="preloader">
  //           <div className="preloader">
  //             <div className="loader"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
      {/* Add/Edit Field Admin */}
      <div
        className={
          !!visitingLeadData ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>Lead Detail</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setLeadViewClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <div className="row">
                <div className="col-xl-12">
                  <div className="contact-tab-wrap">
                    <ul className="contact-nav nav">
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="tab"
                          data-bs-target="#notes"
                          className="active"
                        >
                          <i className="ti ti-notes" />
                          Notes
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="tab"
                          data-bs-target="#basic"
                        >
                          <i className="ti ti-notes" />
                          Basic Info
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="tab"
                          data-bs-target="#contacts"
                        >
                          <i className="ti ti-notes" />
                          Contacts
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* Tab Content */}
                  <div className="contact-tab-view">
                    <div className="tab-content pt-0">
                      {/* Notes */}
                      <div className="tab-pane active show" id="notes">
                        <div className="view-header">
                          <h4>Notes</h4>
                          <div className="text-end">
                            <Link
                              to="#"
                              className="add-comment"
                              onClick={() => {
                                setIsEditingComment((prev) => ({
                                  ...prev,
                                  ["add"]: true,
                                }));
                              }}
                            >
                              <i className="ti ti-square-plus me-1" />
                              Add Comment
                            </Link>
                          </div>
                        </div>
                        <div className="notes-activity">
                          <div
                            className="calls-box"
                            style={{
                              display: isEditingComment?.["add"]
                                ? "block"
                                : "none",
                            }}
                          >
                            <div className="notes-editor">
                              <div className="note-edit-wrap d-block">
                                <div className="summernote">
                                  <Editor
                                    value={editingCommentsContent?.["add"]}
                                    onChange={(e) => onChange("add", e)}
                                  />
                                </div>
                                <div className="text-end note-btns">
                                  <Link
                                    to="#"
                                    className="btn btn-light add-cancel"
                                    onClick={() => {
                                      setIsEditingComment((prev) => ({
                                        ...prev,
                                        ["add"]: false,
                                      }));
                                      setEditingCommentsContent((prev) => ({
                                        ...prev,
                                        ["add"]: "",
                                      }));
                                    }}
                                  >
                                    Cancel
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-primary"
                                    onClick={() => handleOnSaveComment("add")}
                                  >
                                    Save
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          {visitingLeadData?.lead_comments?.length ? (
                            (visitingLeadData?.lead_comments || [])?.map(
                              (leadComment: any) => (
                                <div
                                  className="calls-box"
                                  key={leadComment.comment_id}
                                >
                                  <div className="caller-info">
                                    <div className="calls-user">
                                      <ImageWithBasePath
                                        src="assets/img/profiles/avatar-19.jpg"
                                        alt="img"
                                      />
                                      <div>
                                        <h6>
                                          {`${leadComment.user.first_name} ${leadComment.user.last_name}`.trim() ||
                                            leadComment.user.username}
                                        </h6>
                                        <p>{leadComment?.created_at}</p>
                                      </div>
                                    </div>
                                    <div className="calls-action">
                                      <div className="dropdown action-drop">
                                        <Link
                                          to="#"
                                          className="dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ti ti-dots-vertical" />
                                        </Link>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={() => {
                                              setIsEditingComment((prev) => ({
                                                ...prev,
                                                [leadComment.comment_id]: true,
                                              }));
                                            }}
                                          >
                                            <i className="ti ti-edit text-blue" />
                                            Edit
                                          </Link>
                                          <Link
                                            className="dropdown-item"
                                            to="#"
                                            onClick={() =>
                                              handleOnDeleteComment(
                                                leadComment.comment_id
                                              )
                                            }
                                          >
                                            <i className="ti ti-trash text-danger" />
                                            Delete
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <p>{leadComment?.comment}</p>
                                  <div className="notes-editor">
                                    <div
                                      className="note-edit-wrap"
                                      style={{
                                        display: isEditingComment?.[
                                          leadComment?.comment_id
                                        ]
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <div className="summernote">
                                        <Editor
                                          value={
                                            editingCommentsContent?.[
                                              leadComment.comment_id
                                            ]
                                          }
                                          onChange={(e) =>
                                            onChange(leadComment.comment_id, e)
                                          }
                                        />
                                      </div>
                                      <div className="text-end note-btns">
                                        <Link
                                          to="#"
                                          className="btn btn-light add-cancel"
                                          onClick={() => {
                                            setIsEditingComment((prev) => ({
                                              ...prev,
                                              [leadComment.comment_id]: false,
                                            }));
                                            setEditingCommentsContent(
                                              (prev) => ({
                                                ...prev,
                                                [leadComment.comment_id]:
                                                  leadComment?.comment || "",
                                              })
                                            );
                                          }}
                                        >
                                          Cancel
                                        </Link>
                                        <Link
                                          to="#"
                                          className="btn btn-primary"
                                          onClick={() =>
                                            handleOnSaveComment(
                                              leadComment.comment_id
                                            )
                                          }
                                        >
                                          Save
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          ) : (
                            <div className="calls-box">No Notes</div>
                          )}
                        </div>
                      </div>
                      {/* /Notes */}

                      {/* Basic */}
                      <div className="tab-pane show" id="basic">
                        <div className="view-header">
                          <h4>Detail</h4>
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              dispatch(setEditingLead(visitingLeadData))
                            }
                          >
                            <i className="ti ti-edit mr-2"></i> Edit
                          </button>
                        </div>
                        <div className="notes-activity">
                          <ul className="other-info">
                            {(leadInternalDataFields || []).map(
                              (field: any) => (
                                <li
                                  key={field.original_name}
                                  className="detail-item"
                                >
                                  <span className="other-title h6">
                                    {field.display_name}
                                  </span>
                                  <span>
                                    {field.display_field_type === "boolean" ? (
                                      <Flex
                                        gap="4px 0"
                                        className="align-items-center"
                                      >
                                        <div className="form-switch form-check-md">
                                          <input
                                            className="form-check-input"
                                            type="checkbox"
                                            role="switch"
                                            checked={
                                              visitingLeadData?.[
                                                field.original_name
                                              ] || false
                                            }
                                            disabled
                                          />
                                        </div>
                                      </Flex>
                                    ) : (
                                      <>
                                        {
                                          visitingLeadData?.[
                                            field.original_name
                                          ]
                                        }
                                      </>
                                    )}
                                  </span>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* /Basic */}

                      {/* Basic */}
                      <div className="tab-pane show" id="contacts">
                        <div className="view-header">
                          <h4>Contacts</h4>
                          <div className="text-end">
                            <Link
                              to="#"
                              className="add-contact"
                              onClick={() =>
                                dispatch(
                                  setEditingContact({
                                    contact_id: "add",
                                    lead_id: visitingLeadData?.lead_id,
                                  })
                                )
                              }
                            >
                              <i className="ti ti-square-plus me-1" />
                              Add Contact
                            </Link>
                          </div>
                        </div>
                        <div className="notes-activity">
                          <ul className="other-info">
                            {(visitingLeadData?.contacts || []).map(
                              (contact: any) => (
                                <li
                                  key={contact.id}
                                  className="contact-item calls-box d-flex flex-row justify-content-between"
                                >
                                  <div className="d-flex flex-column gap-1">
                                    <span className="other-title h6">
                                      {contact?.["full_name"]}
                                    </span>
                                    <span style={{ marginLeft: "16px" }}>
                                      {contact?.["phone"]}
                                    </span>
                                    <span style={{ marginLeft: "16px" }}>
                                      {contact?.["email"]}
                                    </span>
                                  </div>
                                  <div>
                                    <button
                                      className="btn btn-primary"
                                      onClick={() =>
                                        dispatch(
                                          setEditingContact({
                                            ...contact,
                                            lead_id: visitingLeadData?.lead_id,
                                          })
                                        )
                                      }
                                    >
                                      <i className="ti ti-edit mr-2"></i> Edit
                                    </button>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* /Contacts */}
                    </div>
                  </div>
                  {/* /Tab Content */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Field Admin */}
    </>
  );
};

export default LeadDetailModal;

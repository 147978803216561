import axios from "../utils/axios";

class CampaignService {
  // Campaign Actions
  async getCampaigns() {
    try {
      const response = await axios.get("/api/contact/campaigns/");
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createNewCampaign(payload) {
    try {
      const response = await axios.post("/api/contact/campaigns/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async getCampaign(id) {
    try {
      await axios.get(`/api/contact/campaigns/${id}/`);
      return id;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editCampaign(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.put(`/api/contact/campaigns/${id}/`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async removeCampaign(id) {
    try {
      await axios.delete(`/api/contact/campaigns/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async filterSortCampaignLeads(
    campaignId,
    filterData = {},
    termData = null,
    order = {
      Id: "desc",
    },
    current = 1,
    pageSize = 50
  ) {
    const checkedFilters = {};
    Object.keys(filterData).forEach((field) => {
      const checkedValues = Object.keys(
        filterData?.[field]?.["terms"] || {}
      ).filter((value) => filterData?.[field]?.["terms"]?.[value]);
      if (checkedValues.length) {
        checkedFilters[field] = {
          type: filterData?.[field]?.["type"] || "",
          terms: checkedValues,
        };
      }
    });
    const payload = {
      filters: checkedFilters,
    };
    if (termData) {
      payload["term"] = termData;
    }

    payload["order"] = order;
    try {
      const response = await axios.post(
        `/api/contact/campaign-leads/${campaignId}/filter_sort_data/?page=${current}&page_size=${pageSize}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }
}

export default new CampaignService();

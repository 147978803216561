import React from "react";
import { all_routes } from "../../router/all_routes";
import { Link } from "react-router-dom";
import ImageWithBasePath from "../../../core/common/imageWithBasePath";

const ErrorNoPermission = () => {
  const route = all_routes;
  return (
    <div className="container">
      <div className="error-box">
        <div className="error-img">
          <ImageWithBasePath
            src="assets/img/authentication/verify-img.png"
            className="img-fluid"
            alt=""
          />
        </div>
        <div className="error-content">
          <h3>Oops, you don't have permission to access this page.</h3>
          <Link to={route.welcomePage} className="btn btn-primary">
            <i className="ti ti-arrow-narrow-left" /> Back to Dashboard
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorNoPermission;

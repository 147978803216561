import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { setGenerateCampaignLinkClose } from "../../../data/redux/campaign/campaign";
import { all_routes } from "../../../../feature-module/router/all_routes";

const GenerateCampaignLinkModal = () => {
  const dispatch = useDispatch<any>();
  const routes = all_routes;

  const generatingRecordId = useSelector(
    (state: any) => state?.campaign?.campaigns?.generate?.data
  );

  const copyToClipboardText = routes.campaignLeads.replace(
    ":campaignId",
    generatingRecordId
  );

  return (
    <>
      {/* Generate Campaign Link */}
      <div
        className={`modal custom-modal fade ${
          generatingRecordId ? "show" : ""
        }`}
        style={{ display: generatingRecordId ? "block" : "none" }}
        id="generate_campaign_link"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 m-0 justify-content-end">
              <button
                className="btn-close"
                onClick={() => dispatch(setGenerateCampaignLinkClose())}
                aria-label="Close"
              >
                <i className="ti ti-x" />
              </button>
            </div>
            <div className="modal-body">
              <div className="success-message text-center">
                <p className="del-info">{copyToClipboardText}</p>
                <div className="col-lg-12 text-center modal-btn">
                  <Link
                    to="#"
                    className="btn btn-light"
                    onClick={() => dispatch(setGenerateCampaignLinkClose())}
                  >
                    Cancel
                  </Link>
                  <Link
                    to="#"
                    className="btn btn-danger"
                    onClick={() => {
                      dispatch(setGenerateCampaignLinkClose());
                      window.open(copyToClipboardText, "_blank");
                    }}
                  >
                    Open in new tab
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Generate Campaign Link */}
    </>
  );
};

export default GenerateCampaignLinkModal;

import axios from "../utils/axios";

class FieldsAdminService {
  // Field Admin Actions
  async getAdminFields(
    page_root = "contact",
    model_name = null,
    model_category = null
  ) {
    const query_params = [];
    if (page_root) {
      query_params.push(`page_root=${page_root}`);
    }
    if (model_name) {
      query_params.push(`model_name=${model_name}`);
    }
    if (model_category) {
      query_params.push(`model_category=${model_category}`);
    }
    try {
      const response = await axios.get(
        `/api/contact/admin-fields/?${query_params.join("&")}`
      );
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async getAdminField(id) {
    try {
      const response = await axios.get(`/api/contact/admin-fields/${id}/`);
      return response.data;
    } catch (err) {
      return [];
    }
  }

  async createAdminField(payload) {
    try {
      const response = await axios.post("/api/contact/admin-fields/", payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async editAdminField(payload) {
    const { id, data } = payload;
    try {
      const response = await axios.patch(
        `/api/contact/admin-fields/${id}/`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err) {
      return {
        error: err,
      };
    }
  }

  async removeAdminField(id) {
    try {
      await axios.delete(`/api/contact/admin-fields/${id}/`);
      return id;
    } catch (err) {
      return null;
    }
  }

  async retrieveAdminFields(page_root, model, category) {
    try {
      const response = await axios.get(
        `/api/contact/generate-admin-fields/?page_root=${page_root}&model=${model}&category=${category}`
      );
      return response.data;
    } catch (err) {
      return null;
    }
  }

  async retrieveModelFields(model) {
    try {
      const response = await axios.get(
        `/api/contact/retrieve-model-fields/?model=${model}`
      );
      return response.data;
    } catch (err) {
      return null;
    }
  }
}

export default new FieldsAdminService();

import { createAsyncThunk } from "@reduxjs/toolkit";
import CampaignService from "../../../../services/campaign";

// Campaign Actions
export const getCampaigns = createAsyncThunk(
  "campaign/retrieve-campaigns",
  async () => {
    const response = await CampaignService.getCampaigns();
    if (!response) {
      throw new Error("Retrieve Campaigns Failed");
    }

    return response;
  }
);

export const createCampaign = createAsyncThunk(
  "campaign/create-campaign",
  async (payload: any) => {
    const response = await CampaignService.createNewCampaign(payload);
    if (!response) {
      throw new Error("Create Campaign Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const getCampaign = createAsyncThunk(
  "campaign/retrieve-campaign",
  async (payload: any) => {
    const { is_new_order, ...rest } = payload;
    const response = await CampaignService.getCampaign(rest);
    if (!response) {
      throw new Error("Get Campaign Failed!");
    }

    return response;
  }
);

export const editCampaign = createAsyncThunk(
  "campaign/edit-campaign",
  async (payload: any) => {
    const response = await CampaignService.editCampaign(payload);
    if (!response) {
      throw new Error("Edit Campaign Failed!");
    }

    if (response && response?.error) {
      throw new Error(JSON.stringify(response?.error));
    }

    return response;
  }
);

export const deleteCampaign = createAsyncThunk(
  "campaign/delete-campaign",
  async (payload: any) => {
    const response = await CampaignService.removeCampaign(payload);
    if (!response) {
      throw new Error("Remove Campaign Failed!");
    }

    return response;
  }
);

export const filterSortCampaignLeads = createAsyncThunk(
  "campaign/filter-sort-campaign-leads",
  async (payload: any) => {
    const { campaignId, filters, term, order, currentPage, pageSize } = payload;
    const response = await CampaignService.filterSortCampaignLeads(
      campaignId,
      filters,
      term,
      order,
      currentPage,
      pageSize
    );
    if (!response) {
      throw new Error("Filter Leads Failed");
    }

    return response;
  }
);

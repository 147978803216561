// index.tsx
import React, { useEffect, useState } from "react";
import { DragSortTable } from "@ant-design/pro-components";
import { DatatableProps } from "../../data/interface"; // Ensure correct path
import { Empty } from "antd";

const Datatable: React.FC<DatatableProps> = ({
  columns,
  dataSource,
  loading = false,
  isSelectable = false,
  onDragEnd,
  onSorterHandler,
  onPaginationHandler,
  pagination,
  tableLayout,
  stickyHeader = false,
  height = "100vh",
}) => {
  const [tableData, setTableData] = useState(dataSource);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const onSelectChange = (newSelectedRowKeys: any[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleDragSortEnd = (
    beforeIndex: number,
    afterIndex: number,
    newDataSource: any
  ) => {
    const currentRowId = tableData?.[beforeIndex]?.id;
    setTableData(newDataSource);
    if (onDragEnd) {
      onDragEnd(currentRowId, afterIndex);
    }
  };

  useEffect(() => {
    setTableData(dataSource);
  }, [JSON.stringify(dataSource)]);

  return (
    <DragSortTable
      className="table dataTable no-footer"
      columns={columns}
      dataSource={tableData}
      rowSelection={isSelectable ? rowSelection : {}}
      rowKey={"id"}
      search={false}
      options={false}
      loading={loading}
      onChange={(pagination, filters, sorter) => {
        onSorterHandler && onSorterHandler(sorter);
        onPaginationHandler && onPaginationHandler(pagination);
      }}
      tableAlertRender={false}
      pagination={
        pagination
          ? {
              ...pagination,
              pageSizeOptions: [10, 20, 50],
              pageSize: pagination?.pageSize ?? 50,
            }
          : false
      }
      tableLayout={tableLayout as any}
      dragSortKey="sort"
      onDragSortEnd={handleDragSortEnd}
      style={{
        minHeight: "252px",
        height: height,
      }}
      locale={{
        emptyText: <Empty description="No Data" />,
      }}
      scroll={{ x: 1500 }}
      sticky={stickyHeader}
    />
  );
};

export default Datatable;

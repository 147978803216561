import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import { Flex, Tag } from "antd";
import {
  getUserGroups,
  getPermissions,
} from "../../../core/data/redux/auth/thunk";
import AddUserGroupModal from "../../../core/modals/menus/usergroups/add_user_group_modal";
import RemoveUserGroupModal from "../../../core/modals/menus/usergroups/remove_user_group_modal";
import {
  setEditingUserGroup,
  setRemoveUserGroup,
} from "../../../core/data/redux/auth/auth";
import pkg from "lodash";
const { isEmpty } = pkg;

const ManageUserGroups = () => {
  const route = all_routes;
  const navigate = useNavigate();

  const userGroupsData =
    useSelector((state: any) => state?.auth?.groups?.data) || [];
  const userGroupsLoading =
    useSelector((state: any) => state?.auth?.groups?.loading) || false;

  const loggedInUser = useSelector(
    (state: any) => state.auth?.currentUser?.data
  );

  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(getUserGroups());
    dispatch(getPermissions());
  }, []);

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      !(loggedInUser?.is_admin || loggedInUser?.is_superuser)
    ) {
      navigate("/no-permission");
    }
  }, [navigate, loggedInUser]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          {record.level < 3 ? (
            <Link
              to={route.menuDetail.replace(":id", record.id)}
              className="profile-split d-flex flex-column cursor-pointer"
              role="button"
            >
              <span>{record.name}</span>
            </Link>
          ) : (
            <span className="profile-split d-flex flex-column cursor-pointer">
              {record.name}
            </span>
          )}
        </div>
      ),
    },
    {
      title: "Members",
      dataIndex: "members",
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.members || []).map((user: any) => (
            <Tag color="processing" key={user.username}>
              {user.username}
            </Tag>
          ))}
        </Flex>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditingUserGroup(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveUserGroup(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-sm-4">
                    <h4 className="page-title">
                      User Groups
                      {userGroupsData?.length ? (
                        <span className="count-title">
                          {userGroupsData?.length}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-sm-8 text-sm-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getUserGroups());
                          dispatch(getPermissions());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
            </div>
            {/* /Page Header */}
            <div className="card main-card">
              <div className="card-body">
                {/* Search */}
                <div className="search-section">
                  <div className="row">
                    <div className="col-md-5 col-sm-4">
                      <div className="form-wrap icon-form">
                        <span className="form-icon">
                          <i className="ti ti-search" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search Groups"
                        />
                      </div>
                    </div>
                    <div className="col-md-7 col-sm-8">
                      <div className="export-list text-sm-end">
                        <ul>
                          <li>
                            <Link
                              to="#"
                              className="btn btn-primary add-popup"
                              onClick={() =>
                                dispatch(setEditingUserGroup(null))
                              }
                            >
                              <i className="ti ti-square-rounded-plus" /> Add
                              Group
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* /Search */}
                {/* Group List */}
                <div className="table-responsive custom-table">
                  <Table
                    dataSource={userGroupsData}
                    columns={columns}
                    loading={userGroupsLoading}
                  />
                </div>
                <div className="row align-items-center">
                  <div className="col-md-6">
                    <div className="datatable-length" />
                  </div>
                  <div className="col-md-6">
                    <div className="datatable-paginate" />
                  </div>
                </div>
                {/* /Group List */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUserGroupModal />
      <RemoveUserGroupModal />
    </>
  );
};

export default ManageUserGroups;

import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";
import pkg from "lodash";
import { Alert } from "antd";
import { getErrorMessage } from "../../../../utils/helper";
import { setEditingCampaignClose } from "../../../data/redux/campaign/campaign";
import "./index.scss";
import {
  createCampaign,
  editCampaign,
} from "../../../data/redux/campaign/thunk";

const AddCampaignModal = () => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.campaign?.campaigns?.edit?.loading
  );
  const availableUserGroupsData =
    useSelector((state: any) => state?.auth?.groups?.data) || [];

  const editingData = useSelector(
    (state: any) => state?.campaign?.campaigns?.edit?.data
  );
  const menuData = useSelector((state: any) => state.menu.menus?.list) || [];

  const formik = useFormik({
    initialValues: {
      title: editingData?.title || "",
      status: editingData ? editingData.status : "active",
      assigned_user_groups:
        editingData?.assigned_user_groups?.map((user: any) => user.id) || [],
      submit: null,
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().max(255).required("TItle is required"),
      assigned_user_groups: Yup.array(),
    }),
    enableReinitialize: true,
    onSubmit: async (values, { resetForm, setErrors }) => {
      try {
        let newCampaign: any = values;

        newCampaign.assigned_user_groups_ids =
          values?.assigned_user_groups || [];
        newCampaign.assigned_user_groups =
          userOptions?.assigned_user_groups?.filter((user: any) =>
            ((values?.assigned_user_groups || []) as any[]).includes(user.id)
          );

        let res: any;
        if (editingData) {
          res = await dispatch(
            editCampaign({ id: editingData.campaign_id, data: newCampaign })
          );
        } else {
          res = await dispatch(createCampaign(newCampaign));
        }

        if (res && res?.error) {
          let errorData;
          try {
            errorData = JSON.parse(res?.error?.message);
            setErrors(errorData);
          } catch (_) {
            errorData = "";
            setErrors({ submit: getErrorMessage(errorData) });
          }
        } else {
          resetForm();
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    values,
    handleChange,
    handleBlur,
  } = formik;

  const userOptions = useMemo(() => {
    if (availableUserGroupsData?.length) {
      const options = availableUserGroupsData.map((group: any) => ({
        label: group.name,
        value: group.id,
      }));
      return options;
    }
    return [];
  }, [JSON.stringify(menuData)]);

  const onCloseModal = () => {
    dispatch(setEditingCampaignClose());
  };

  return (
    <FormikProvider value={formik}>
      {/* Add/Edit Campaign */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>{editingData ? "Edit Campaign" : "Add New Campaign"}</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => onCloseModal()}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                {/* Campaign Info */}

                <div className="row gy-2">
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Campaign Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        value={values.title}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.title && errors.title && (
                      <Alert message={errors.title as string} type="error" />
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">
                        Assigned Groups <span className="text-danger">*</span>
                      </label>
                      <Select
                        defaultValue={[]}
                        isMulti
                        name="assigned_user_groups"
                        options={userOptions}
                        onBlur={handleBlur}
                        value={
                          userOptions
                            ? userOptions.filter((option: any) =>
                                (
                                  (values.assigned_user_groups || []) as any[]
                                ).includes(option.value)
                              )
                            : []
                        }
                        onChange={(options: any) => {
                          setFieldValue(
                            "assigned_user_groups",
                            (options || []).map((option: any) => option.value)
                          );
                        }}
                      />
                    </div>
                    {touched.assigned_user_groups &&
                      errors.assigned_user_groups && (
                        <Alert
                          message={errors.assigned_user_groups as string}
                          type="error"
                        />
                      )}
                  </div>
                  <div className="col-md-12">
                    <div className="form-wrap">
                      <label className="col-form-label">Status</label>
                      <input
                        type="text"
                        name="status"
                        className="form-control"
                        value={values.status}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                    {touched.status && errors.status && (
                      <Alert message={errors.status as string} type="error" />
                    )}
                  </div>
                </div>

                <div className="submit-button text-end">
                  <Link
                    to="#"
                    className="btn btn-light sidebar-close"
                    onClick={() => onCloseModal()}
                  >
                    Cancel
                  </Link>
                  <button type="submit" className="btn btn-primary">
                    {editingData ? "Edit" : "Create"}
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Group */}
    </FormikProvider>
  );
};

export default AddCampaignModal;

import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Table from "../../../core/common/dataTable/index";
import { useDispatch, useSelector } from "react-redux";
import { all_routes } from "../../router/all_routes";
import CollapseHeader from "../../../core/common/collapse-header";
import { Flex, Tag } from "antd";
import pkg from "lodash";
import { getCampaigns } from "../../../core/data/redux/campaign/thunk";
import RemoveCampaignModal from "../../../core/modals/campaign/manage/remove_campaign_modal";
import {
  setEditingCampaign,
  setGenerateCampaignLink,
  setRemoveCampaign,
} from "../../../core/data/redux/campaign/campaign";
import AddCampaignModal from "../../../core/modals/campaign/manage/add_campaign_modal";
import "./index.scss";
import GenerateCampaignLinkModal from "../../../core/modals/campaign/manage/generate_campaign_link";
const { isEmpty } = pkg;

const CampaignList = () => {
  const route = all_routes;
  const navigate = useNavigate();

  let { menuId } = useParams();

  const campaignData =
    useSelector((state: any) => state.campaign.campaigns?.list) || [];
  const dataLoading = useSelector(
    (state: any) => state.campaign.campaigns?.loading
  );
  const loggedInUser = useSelector(
    (state: any) => state.auth?.currentUser?.data
  );

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (dispatch) {
      dispatch(getCampaigns());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !isEmpty(loggedInUser) &&
      !(loggedInUser?.is_admin || loggedInUser?.is_superuser)
    ) {
      navigate("/no-permission");
    }
  }, [navigate, loggedInUser]);

  const columns = [
    {
      title: "",
      dataIndex: "sort",
      width: 60,
      className: "drag-visible",
    },
    {
      title: "Name",
      dataIndex: "title",
      width: 200,
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <Link
            to={route.campaignAdmin.replace(":campaignId", record.campaign_id)}
            className="profile-split d-flex flex-column cursor-pointer"
            role="button"
          >
            <span>{record.title}</span>
          </Link>
        </div>
      ),
    },

    {
      title: "Assigned Groups",
      dataIndex: "assigned_user_groups",
      width: 200,
      render: (text: string, record: any, index: number) => (
        <Flex gap="4px 0">
          {(record?.assigned_user_groups || []).map((group: any) => (
            <Tag color="processing" key={group.name}>
              {group.name}
            </Tag>
          ))}
        </Flex>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      width: 200,
      render: (text: string, record: any, index: number) => (
        <div className="table-avatar d-flex align-items-center" key={index}>
          <span className="profile-split d-flex flex-column cursor-pointer text-uppercase">
            {record.status}
          </span>
        </div>
      ),
    },

    {
      title: "Actions",
      dataIndex: "actions",
      render: (text: string, record: any, index: number) => (
        <div className="dropdown table-action" key={index}>
          <Link
            to="#"
            className="action-icon"
            data-bs-toggle="dropdown"
            aria-expanded="true"
          >
            <i className="fa fa-ellipsis-v"></i>
          </Link>
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              position: "absolute",
              inset: "0px auto auto 0px",
              margin: "0px",
              transform: "translate3d(-99.3333px, 35.3333px, 0px)",
            }}
            data-popper-placement="bottom-start"
          >
            <Link
              className="dropdown-item"
              to="#"
              onClick={() =>
                dispatch(setGenerateCampaignLink(record.campaign_id))
              }
            >
              <i className="ti ti-window-maximize text-info"></i> Generate Link
            </Link>
            <Link
              className="dropdown-item edit-popup"
              to="#"
              onClick={() => dispatch(setEditingCampaign(record))}
            >
              <i className="ti ti-edit text-blue"></i> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => dispatch(setRemoveCampaign(record.id))}
            >
              <i className="ti ti-trash text-danger"></i> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* Page Header */}
              <div className="page-header">
                <div className="row align-items-center">
                  <div className="col-8">
                    <h4 className="page-title">
                      Campaigns
                      {campaignData?.length ? (
                        <span className="count-title">
                          {campaignData?.length}
                        </span>
                      ) : null}
                    </h4>
                  </div>
                  <div className="col-4 text-end">
                    <div className="head-icons">
                      <CollapseHeader
                        onRefresh={() => {
                          dispatch(getCampaigns());
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* /Page Header */}
              <div className="card main-card">
                <div className="card-body">
                  {/* Search */}
                  <div className="search-section">
                    <div className="row">
                      <div className="col-md-5 col-sm-4">
                        <div className="form-wrap icon-form">
                          <span className="form-icon">
                            <i className="ti ti-search" />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Campaigns"
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-8">
                        <div className="export-list text-sm-end">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                className="btn btn-primary add-popup"
                                onClick={() =>
                                  dispatch(setEditingCampaign(null))
                                }
                              >
                                <i className="ti ti-square-rounded-plus" /> Add
                                Campaign
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /Search */}
                  {/* Campaign List */}
                  <div className="table-responsive custom-table">
                    <Table
                      dataSource={campaignData}
                      columns={columns}
                      loading={dataLoading}
                    />
                  </div>
                  <div className="row align-items-center">
                    <div className="col-md-6">
                      <div className="datatable-length" />
                    </div>
                    <div className="col-md-6">
                      <div className="datatable-paginate" />
                    </div>
                  </div>
                  {/* /Campaign List */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <AddCampaignModal />
      <RemoveCampaignModal />

      <GenerateCampaignLinkModal />
    </div>
  );
};

export default CampaignList;

import React from "react";
import { Link } from "react-router-dom";
import { setHeaderCollapse } from "../data/redux/common";
import { useDispatch, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface CollapseHeaderProps {
  onRefresh?: () => void;
  onNewTab?: () => void;
  openNewTabButtonAvailable?: boolean;
}

const CollapseHeader = ({
  onRefresh,
  onNewTab,
  openNewTabButtonAvailable,
}: CollapseHeaderProps) => {
  const dispatch = useDispatch();
  const headerCollapse = useSelector(
    (state: any) => state.common.headerCollapse
  );

  const toggleHeaderCollapse = () => {
    dispatch(setHeaderCollapse(!headerCollapse));
  };

  return (
    <>
      {openNewTabButtonAvailable && (
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
              Open New Tab
            </Tooltip>
          }
        >
          <Link
            to="#"
            onClick={() => {
              if (onNewTab) onNewTab();
            }}
          >
            <i className="ti-new-window" />
          </Link>
        </OverlayTrigger>
      )}
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="refresh-tooltip" style={{ position: "fixed" }}>
            Refresh
          </Tooltip>
        }
      >
        <Link
          to="#"
          onClick={() => {
            if (onRefresh) onRefresh();
          }}
        >
          <i className="ti ti-refresh-dot" />
        </Link>
      </OverlayTrigger>
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip id="collapse-tooltip" style={{ position: "fixed" }}>
            Collapse
          </Tooltip>
        }
      >
        <Link to="#" id="collapse-header" onClick={toggleHeaderCollapse}>
          <i className="ti ti-chevrons-up" />
        </Link>
      </OverlayTrigger>
    </>
  );
};

export default CollapseHeader;

import { createSlice } from "@reduxjs/toolkit";
import {
  createCampaign,
  editCampaign,
  getCampaign,
  getCampaigns,
  deleteCampaign,
  filterSortCampaignLeads,
} from "./thunk";
import { sortBy } from "lodash";
import { TablePaginationConfig } from "antd";

const initialState: {
  campaigns: {
    edit: {
      data: any;
      loading: boolean;
    };
    delete: {
      data: any;
    };
    generate: {
      data: any;
    };
    loading: boolean;
    list: any[];
    leads: {
      [campaignId: string]: {
        data: any[];
        pagination: TablePaginationConfig;
      };
    };
  };
} = {
  campaigns: {
    loading: true,
    edit: {
      data: null,
      loading: false,
    },
    generate: {
      data: null,
    },
    delete: {
      data: null,
    },
    list: [],
    leads: {},
  },
};

export const campaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    // Campaign Actions
    setEditingCampaign: (state, action) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        edit: {
          data: action.payload,
          loading: true,
        },
      };
    },
    setEditingCampaignClose: (state) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        edit: {
          data: null,
          loading: false,
        },
      };
    },
    setRemoveCampaign: (state, action) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        delete: {
          data: action.payload,
        },
      };
    },
    setRemoveCampaignClose: (state) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        delete: {
          data: null,
        },
      };
    },

    setGenerateCampaignLink: (state, action) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        generate: {
          data: action.payload,
        },
      };
    },
    setGenerateCampaignLinkClose: (state) => {
      state.campaigns = {
        ...(state.campaigns || {}),
        generate: {
          data: null,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Campaign Actions
      .addCase(createCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy([...(state.campaigns.list || []), data], "updated_at"),
        };
      })
      .addCase(createCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
        };
      })
      .addCase(editCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            ...(state.campaigns.edit || {}),
            loading: true,
          },
        };
      })
      .addCase(editCampaign.fulfilled, (state, action) => {
        const data: any = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: false,
          },
          list: sortBy(
            [
              ...state.campaigns.list?.filter(
                (item) => item.campaign_id !== data.campaign_id
              ),
              data,
            ],
            "updated_at"
          ),
        };
      })
      .addCase(editCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          edit: {
            data: null,
            loading: true,
          },
        };
      })
      .addCase(getCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(getCampaign.fulfilled, (state, action) => {
        const data = action.payload;
        const existingData = (state.campaigns.list || []).filter(
          (item: any) => item.campaign_id !== data?.campaign_id
        );
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: sortBy([...existingData, data], "updated_at"),
        };
      })
      .addCase(getCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      })
      .addCase(getCampaigns.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(getCampaigns.fulfilled, (state, action) => {
        const CampaignsData = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: sortBy(CampaignsData, "updated_at"),
        };
      })
      .addCase(getCampaigns.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      })
      .addCase(deleteCampaign.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        const CampaignId = action.payload;
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
          list: (state.campaigns.list || []).filter(
            (item: any) => item.campaign_id !== CampaignId
          ),
          delete: {
            data: null,
          },
        };
      })
      .addCase(deleteCampaign.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      })
      .addCase(filterSortCampaignLeads.pending, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: true,
        };
      })
      .addCase(filterSortCampaignLeads.fulfilled, (state, action) => {
        const { results, count } = action.payload;
        const campaignId = action.meta.arg?.campaignId;
        if (campaignId) {
          state.campaigns = {
            ...(state.campaigns || {}),
            loading: false,
            list: results,
            leads: {
              ...(state?.campaigns?.leads || {}),
              [campaignId]: {
                data: results,
                pagination: {
                  total: count,
                  current: action.meta.arg?.currentPage || 1,
                },
              },
            },
          };
        }
      })
      .addCase(filterSortCampaignLeads.rejected, (state, action) => {
        state.campaigns = {
          ...(state.campaigns || {}),
          loading: false,
        };
      });
  },
});

export const {
  setEditingCampaign,
  setEditingCampaignClose,
  setRemoveCampaign,
  setRemoveCampaignClose,
  setGenerateCampaignLink,
  setGenerateCampaignLinkClose,
} = campaignSlice.actions;

export default campaignSlice.reducer;

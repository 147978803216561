import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";
import { setContactViewClose } from "../../data/redux/contacts/contacts";
import { getContact } from "../../data/redux/contacts/thunk";

const ContactDetailModal = () => {
  const dispatch = useDispatch<any>();
  const { data: visitingContactData } =
    useSelector((state: any) => state.contact.contacts?.view) || [];

  useEffect(() => {
    if (visitingContactData?.contact_id)
      dispatch(getContact(visitingContactData.contact_id));
  }, [visitingContactData?.contact_id]);

  return (
    <>
      {/* Add/Edit Field Admin */}
      <div
        className={
          !!visitingContactData ? "toggle-popup sidebar-popup" : "toggle-popup"
        }
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>Contact Detail</h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setContactViewClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <div className="row">
                <div className="col-xl-12">
                  <div className="contact-tab-wrap">
                    <ul className="contact-nav nav">
                      <li>
                        <Link
                          to="#"
                          data-bs-toggle="tab"
                          data-bs-target="#leads"
                          className="active"
                        >
                          <i className="ti ti-notes" />
                          Leads
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* Tab Content */}
                  <div className="contact-tab-view">
                    <div className="tab-content pt-0">
                      {/* Basic */}
                      <div className="tab-pane active show" id="leads">
                        <div className="view-header">
                          <h4>Leads</h4>
                        </div>
                        <div className="notes-activity">
                          <ul className="other-info">
                            {(visitingContactData?.contact_leads || []).map(
                              (lead: any) => (
                                <li
                                  key={lead.lead_id}
                                  className="contact-item calls-box d-flex flex-row justify-content-between"
                                >
                                  <div className="d-flex flex-column gap-1">
                                    <span className="other-title h6">
                                      {lead?.["original_lead_id"]}
                                    </span>
                                    <span style={{ marginLeft: "16px" }}>
                                      {lead?.["section_name"]}
                                    </span>
                                    <span style={{ marginLeft: "16px" }}>
                                      {lead?.["substage"]}
                                    </span>
                                  </div>
                                </li>
                              )
                            )}
                          </ul>
                        </div>
                      </div>
                      {/* /Contacts */}
                    </div>
                  </div>
                  {/* /Tab Content */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Field Admin */}
    </>
  );
};

export default ContactDetailModal;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Navigate, Outlet } from "react-router";
import Header from "../core/common/header";
import Sidebar from "../core/common/sidebar";
import { getMenuGroups, getMenus } from "../core/data/redux/menu/thunk";
import { getUserGroups, getUsers } from "../core/data/redux/auth/thunk";
import {
  getAdminFields,
  retrieveModelFields,
} from "../core/data/redux/fieldsAdmin/thunk";

const Feature = () => {
  const dispatch = useDispatch<any>();
  const isAuthenticated = useSelector(
    (state: any) => !!state.auth.currentUser.token
  );

  const headerCollapse = useSelector(
    (state: any) => state.common.headerCollapse
  );
  const mobileSidebar = useSelector((state: any) => state.common.mobileSidebar);
  const miniSidebar = useSelector((state: any) => state.common.miniSidebar);
  const expandMenu = useSelector((state: any) => state.common.expandMenu);

  useEffect(() => {
    if (isAuthenticated && dispatch) {
      dispatch(getMenuGroups());
      dispatch(getMenus());
      dispatch(getUsers());
      dispatch(getUserGroups());
      dispatch(
        getAdminFields({
          page_root: "contact",
        })
      );
      dispatch(
        getAdminFields({
          page_root: "lead",
        })
      );
      dispatch(retrieveModelFields({ model_name: "Lead" }));
      dispatch(retrieveModelFields({ model_name: "ContactNew" }));
    }
  }, [isAuthenticated, dispatch]);

  return isAuthenticated ? (
    <div
      className={`
      ${miniSidebar ? "mini-sidebar" : ""}
      ${expandMenu ? "expand-menu" : ""}`}
    >
      <div
        className={`layout-main-wrapper main-wrapper 
        ${headerCollapse ? "header-collapse" : ""} 
        ${mobileSidebar ? "slide-nav" : ""}`}
      >
        <Header />
        <Sidebar />
        <Outlet />
      </div>
      <div className="sidebar-overlay"></div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default Feature;

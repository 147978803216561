import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import { Formik } from "formik";
import * as Yup from "yup";
import { Alert } from "antd";
import { getErrorMessage } from "../../../../utils/helper";
import { setEditingContactClose } from "../../../data/redux/contacts/contacts";
import { createContact, editContact } from "../../../data/redux/contacts/thunk";
import {
  getInitialValueByFieldType,
  getValidationSchemaByFieldType,
} from "../utils/helper";

interface AddContactModalProps {
  onSubmitFinish?: (lead_id: string | undefined) => void;
}

const AddContactModal = ({ onSubmitFinish }: AddContactModalProps) => {
  const dispatch = useDispatch<any>();
  const modalToggle = useSelector(
    (state: any) => state?.contact?.contacts?.edit?.loading
  );
  const editingData = useSelector(
    (state: any) => state?.contact?.contacts?.edit?.data
  );

  const fieldsAdminData =
    useSelector((state: any) => state.fieldsAdmin.admin.list) || {};

  const [requiredFields, setRequiredFields] = useState<any[]>([]);
  const [showFields, setShowFields] = useState<any[]>([]);

  useEffect(() => {
    const pageFieldAdminData =
      fieldsAdminData?.["contact"]?.["ContactNew"]?.["main"]?.["data"];
    if (pageFieldAdminData?.length) {
      const isShownFields = pageFieldAdminData.filter(
        (field: any) => field.is_show && field.original_name !== "contact_id"
      );
      setShowFields(isShownFields);

      const result = isShownFields.filter((field: any) => field.is_required);
      setRequiredFields(result);
    }
  }, [fieldsAdminData]);

  const initialValues = useMemo(() => {
    if (showFields?.length) {
      const result = showFields.reduce(
        (initialData: any, currentField: any) => {
          const { original_name, display_field_type, field_type } =
            currentField;

          initialData[original_name] =
            editingData?.contact_id === "add"
              ? getInitialValueByFieldType(display_field_type)
              : editingData?.[original_name];
          return initialData;
        },
        {}
      );
      return result;
    }
    return {};
  }, [JSON.stringify(showFields), JSON.stringify(editingData)]);

  const validationSchema = useMemo(() => {
    if (requiredFields?.length) {
      const result = requiredFields.reduce(
        (initialSchema: any, currentField: any) => {
          const { original_name, display_name, display_field_type } =
            currentField;
          initialSchema[original_name] = getValidationSchemaByFieldType(
            display_name,
            display_field_type
          );
          return initialSchema;
        },
        {}
      );

      return result;
    }
    return {};
  }, [JSON.stringify(requiredFields)]);

  return (
    <>
      {/* Add/Edit Contact */}
      <div
        className={modalToggle ? "toggle-popup sidebar-popup" : "toggle-popup"}
      >
        <div className="sidebar-layout">
          <div className="sidebar-header">
            <h4>
              {editingData?.contact_id === "add"
                ? "Add New Contact"
                : "Edit Contact"}
            </h4>
            <Link
              to="#"
              className="sidebar-close toggle-btn"
              onClick={() => dispatch(setEditingContactClose())}
            >
              <i className="ti ti-x" />
            </Link>
          </div>
          <div className="toggle-body">
            <div className="pro-create">
              <Formik
                initialValues={{ ...initialValues, submit: null }}
                validationSchema={Yup.object().shape(validationSchema)}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm, setErrors }) => {
                  try {
                    let newContact: any = values;

                    let res: any;
                    if (editingData?.contact_id === "add") {
                      res = await dispatch(
                        createContact({
                          ...newContact,
                          lead: editingData?.lead_id,
                        })
                      );
                    } else {
                      res = await dispatch(
                        editContact({
                          contact_id: editingData.contact_id,
                          data: newContact,
                        })
                      );
                    }

                    if (res && res?.error) {
                      let errorData;
                      try {
                        errorData = JSON.parse(res?.error?.message);
                        setErrors(errorData);
                      } catch (_) {
                        errorData = "";
                        setErrors({ submit: getErrorMessage(errorData) });
                      }
                    } else {
                      onSubmitFinish && onSubmitFinish(editingData?.lead_id);
                      resetForm();
                    }
                  } catch (error) {
                    console.error(error);
                  }
                }}
              >
                {({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  touched,
                  values,
                }) => (
                  <form noValidate onSubmit={handleSubmit}>
                    {/* Contact Info */}

                    <div className="row gy-2">
                      {showFields.map(
                        ({
                          display_name,
                          display_field_type,
                          original_name,
                        }) => (
                          <div className="col-md-12">
                            <div className="form-wrap">
                              <label className="col-form-label">
                                {display_name}
                              </label>
                              {display_field_type === "select" ? (
                                <Select
                                  defaultValue={[]}
                                  name={original_name}
                                  options={[]}
                                  onBlur={handleBlur}
                                  value={null}
                                  onChange={(option: any) => {
                                    setFieldValue(original_name, option.value);
                                  }}
                                />
                              ) : display_field_type === "boolean" ? (
                                <div className="form-switch form-check-md">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked={values?.[original_name]}
                                    name={original_name}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </div>
                              ) : display_field_type === "text" ? (
                                <textarea
                                  name={original_name}
                                  className="form-control"
                                  value={values?.[original_name]}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              ) : (
                                <input
                                  type={
                                    display_field_type === "char"
                                      ? "text"
                                      : (
                                          display_field_type as string
                                        )?.includes("email")
                                      ? "email"
                                      : (
                                          display_field_type as string
                                        )?.includes("phone")
                                      ? "tel"
                                      : "number"
                                  }
                                  name={original_name}
                                  className="form-control"
                                  value={values?.[original_name]}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                            {touched?.[original_name] &&
                              errors?.[original_name] && (
                                <Alert
                                  message={errors?.[original_name] as string}
                                  type="error"
                                />
                              )}
                          </div>
                        )
                      )}
                    </div>
                    <div className="row gy-2">
                      <div className="col-md-12">
                        <div className="form-wrap">
                          {errors.submit && (
                            <Alert
                              message={errors.submit as string}
                              type="error"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="submit-button text-end">
                      <Link
                        to="#"
                        className="btn btn-light sidebar-close"
                        onClick={() => dispatch(setEditingContactClose())}
                      >
                        Cancel
                      </Link>
                      <button type="submit" className="btn btn-primary">
                        {editingData?.contact_id === "add" ? "Create" : "Edit"}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
      {/* /Add/Edit Contact */}
    </>
  );
};

export default AddContactModal;
